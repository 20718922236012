import { HStack, Icon, Text, StyleProps, Image } from "@chakra-ui/react";
import { FC } from "react";
import CustomImage from "~/components/CustomImage";

const Location: FC<
  StyleProps & {
    location?: string;
    noOfLines?: number;
  }
> = ({ location, noOfLines, ...styleProps }) => {
  return (
    <HStack spacing={1} w="full" alignItems="flex-start" {...styleProps}>
      <CustomImage src="/assets/images/event/pin.svg" w={4} h={4} />
      <Text fontSize="xs" color="gray.500" noOfLines={noOfLines ?? 2}>
        {location}
      </Text>
    </HStack>
  );
};

export default Location;
